import { defineStore } from 'pinia';
import { HeadlessService } from '@novu/headless';

export const useNotificationStore = defineStore('notifications', {
    state: () => ({
        data: [],
        hasMore: false,
        novu: null,
        page: 0,
        unread: 0,
        unreadCounts: {}, // New state to hold unread counts by group
        query: {},
    }),

    getters: {
        /**
         * Count the notifications
         * @returns {Number}
         */
        count() {
            return this.data.length;
        },

        /**
         * Returns the selected notifications ids
         * @returns {Array}
         */
        selected() {
            return this.data
                .filter((notification) => notification.selected)
                .map((notification) => notification._id);
        },
    },

    actions: {
        /**
         * Select or unselect all the notifications
         * @param {Boolean} checked
         */
        checkAll(checked) {
            this.data = this.data.map((notification) => {
                notification.selected = checked;
                return notification;
            });
        },

        /**
         * Fetch the notifications for a given page
         * @param {Number} page
         */
        fetch({ page, callback, init }) {
            if (!this.novu) {
                return;
            }

            this.novu.fetchNotifications({
                listener: () => {},
                onSuccess: (response) => {
                    if (init !== undefined) {
                        this.data = response.data;
                    } else {
                        this.set(response.data);
                    }

                    this.hasMore = response.hasMore;
                    this.calculateUnreadCounts(); // Update unread counts after fetching

                    if (callback !== undefined) {
                        callback();
                    }
                },
                page: page,
                query: this.query,
            });
        },

        /**
         * Calculate unread notification counts by group
         */
        calculateUnreadCounts() {
            const counts = this.data.reduce((acc, notification) => {
                if (!notification.read) {
                    const group = notification.payload.group || 'all';
                    if (!acc[group]) acc[group] = 0;
                    acc[group]++;
                }
                return acc;
            }, {});

            this.unreadCounts = counts;
        },

        /**
         * Fetch the unread notifications
         */
        fetchUnread() {
            if (!this.novu) {
                return;
            }

            this.novu.fetchUnreadCount({
                listener: () => {},
                onSuccess: (data) => {
                    this.unread = data.count;
                },
                query: this.query,
            });
        },

        /**
         * Initialize the Novu service
         */
        init() {
            const config = useRuntimeConfig();
            const { $me } = useNuxtApp();

            // Init Novu Service
            if ($me.get()?.novu_id) {
                this.novu = useState('novu', () => {
                    let novu = new HeadlessService({
                        applicationIdentifier: config.public.novu.applicationIdentifier,
                        subscriberId: `${$me.get().id}`,
                    });

                    novu.initializeSession({
                        listener: () => {},
                        onSuccess: () => {
                            this.watch();

                            if (!this.data.length) {
                                // Fetch notifications on the first page
                                this.page = 0;
                                this.fetch({ page: this.page });
                                this.fetchUnread();
                            }
                        },
                        onError: (error) => {
                            console.error('Novu initializeSession error : ', error);
                        },
                    });

                    return novu;
                });
            }
        },

        /**
         * Load more notifications
         */
        loadMore(callback) {
            // Check if there are more notifications to load
            if (!this.hasMore) {
                if (callback) {
                    callback(); // If there's a callback, call it anyway to ensure UI can stop loading
                }
                return;
            }
            this.page++;
            this.fetch({ page: this.page, callback: callback });
        },

        /**
         * Mark the selected notifications as read
         */
        markSelectedAsRead(callback) {
            if (!this.novu) {
                return;
            }

            let selectedIds = this.selected;

            if (!selectedIds.length) {
                this.markAllAsRead(callback);
                return;
            }

            this.novu.markNotificationsAs({
                listener: () => {},
                onSuccess: () => {
                    this.data = this.data.map((notification) => {
                        if (selectedIds.includes(notification._id)) {
                            notification.read = true;
                            notification.selected = false;
                        }
                        return notification;
                    });

                    this.calculateUnreadCounts(); // Update unread counts after marking as read

                    if (callback !== undefined) {
                        callback();
                    }
                },
                messageId: selectedIds,
                mark: { seen: true, read: true },
            });
        },

        /**
         * Mark all notifications as read
         */
        markAllAsRead(callback) {
            if (!this.novu) {
                return;
            }

            this.novu.markAllMessagesAsRead({
                listener: () => {},
                onSuccess: () => {
                    this.data = this.data.map((notification) => {
                        notification.read = true;
                        return notification;
                    });

                    this.calculateUnreadCounts(); // Update unread counts after marking all as read

                    if (callback !== undefined) {
                        callback();
                    }
                },
            });
        },

        /**
         * Add the notifications to the current data set.
         *
         * New notifications can be added before or after the current notifications.
         *
         * @param {Array} notifications
         * @param {Boolean} before
         */
        set(notifications, before) {
            let group = this.query?.payload?.group || 'all';

            // Si on filtre par group, applique le filtre avant d'ajouter les notifications
            if (group !== 'all') {
                notifications = notifications.filter(
                    (notification) => notification.group === this.query.payload.group
                );
            }

            this.data = before ? notifications.concat(this.data) : this.data.concat(notifications);

            this.calculateUnreadCounts(); // Update unread counts after setting new notifications
        },

        /**
         * Watch the notifications
         */
        watch() {
            if (!this.novu) {
                return;
            }

            // Unread count listener
            this.novu.listenUnreadCountChange({
                // this will run every time there's a change in the `unread_count` in real-time
                listener: (unreadCount) => {
                    this.unread = unreadCount;
                },
            });

            // Notification received listener
            this.novu.listenNotificationReceive({
                // this will run every time there's a change in the `unseen_count` in real-time
                listener: (message) => {
                    this.set([message], true);
                },
            });
        },
    },
});
