<script setup>
    const props = defineProps({
        type: {
            type: String,
            required: true,
        },

        notificationType: {
            type: Object,
            default: () => ({}),
        },

        severity: {
            type: String,
            default: 'info',
        },
    });

    const iconName = computed(() => {
        if (props.notificationType.icon) {
            return props.notificationType.icon;
        }

        switch (props.type) {
            case 'up':
                return 'material-symbols:expand-circle-up';

            case 'success':
                return 'material-symbols:check-circle-rounded';

            case 'question':
                return 'material-symbols:help';

            default:
                return `material-symbols:${props.type}-rounded`;
        }
    });

    const severity = computed(() => {
        return props.type === 'info'
            ? props.severity
            : props.type;
    })
</script>

<template>
    <div class="notification__icon" :class="`notification__icon--${severity}` + (props.notificationType?.icon ? ' rounded' : '')">
        <Icon :name="iconName" :size="props.notificationType?.icon ? '1rem' : '2rem'" />
    </div>
</template>

<style lang="scss" scoped>
    .notification__icon {

        &.rounded {
            @include flex-center;
            flex-shrink: 0;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            color: white !important;
        }

        &:not(.rounded) {
            background: transparent !important;
            color: #808080;
        }


        &.notification__icon--info {
            background: #2969FF;
            color: #2969FF;
        }

        &.notification__icon--error {
            background: #D82C0D;
            color: #D82C0D;
        }

        &.notification__icon--success {
            background: #008060;
            color: #008060;
        }

        &.notification__icon--warning {
            background: #FFC453;
            color: #FFC453;
        }
    }
</style>
