<script setup>
    import PrimevueCheckbox from 'primevue/checkbox';
    import PrimevueDropdown from 'primevue/dropdown';
    import PrimevueSidebar from 'primevue/sidebar';
    import PrimevueBadge from 'primevue/badge';
    import ProgressSpinner from 'primevue/progressspinner';
    import { useNotificationStore } from '~/stores/notification';
    import { UserRoles } from '~/enum';

    const { $me } = useNuxtApp();
    const { t: $t } = useI18n();

    const notificationStore = useNotificationStore();

    const notificationIsOpen = ref(false);
    const loadingMore = ref(false);
    const loadingRead = ref(false);
    const activeFilter = ref('all');
    const activeTabIndex = ref(0);

    // List of notification types
    let notificationTypes = {
        all: {
            label: $t('All'),
            value: 'all',
            icon: '',
        },
        bell: {
            label: $t('General'),
            value: 'bell',
            icon: 'mdi:bell',
        },
        buyer: {
            label: $t('Buyer'),
            value: 'buyer',
            icon: 'mdi:account',
            roles: UserRoles.managers(),
        },
        seller: {
            label: $t('Seller'),
            value: 'seller',
            icon: 'mdi:account',
            roles: UserRoles.managers(),
        },
        bids: {
            label: $t('Auctions'),
            value: 'bids',
            icon: 'ri:auction-fill',
            roles: UserRoles.managers(),
        },
        fulfillment: {
            label: $t('Fulfillment'),
            value: 'fulfillment',
            icon: 'mdi:truck',
            roles: UserRoles.managers(),
        },
        money: {
            label: $t('Finances'),
            value: 'money',
            icon: 'mdi:bank',
            roles: UserRoles.managers(),
        },
        message_buyer: {
            label: $me.isAdmin() ? $t('Buyer') : $t('Messages'),
            value: 'message_buyer',
            icon: 'mdi:email',
            roles: [UserRoles.BUYER, ...UserRoles.managers()],
        },
        message_seller: {
            label: $me.isAdmin() ? $t('Seller') : $t('Messages'),
            value: 'message_seller',
            icon: 'mdi:email',
            roles: [UserRoles.SELLER, ...UserRoles.managers()],
        },
        message_logistician: {
            label: $me.isAdmin() ? $t('Logistics') : $t('Messages'),
            value: 'message_logistician',
            icon: 'mdi:email',
            roles: [UserRoles.LOGISTICIAN, ...UserRoles.managers()],
        },
        message_transporter: {
            label: $me.isAdmin() ? $t('Transports') : $t('Messages'),
            value: 'message_transporter',
            icon: 'mdi:email',
            roles: [UserRoles.TRANSPORTER, ...UserRoles.managers()],
        },
    };

    const filters = ref([
        {
            label: $t('Notifications'),
            items: [
                { ...notificationTypes.all },
                { ...notificationTypes.bell },
                { ...notificationTypes.buyer },
                { ...notificationTypes.seller },
                { ...notificationTypes.bids },
                { ...notificationTypes.fulfillment },
                { ...notificationTypes.money },
            ].filter((group) => !group.roles || group.roles.includes($me.get()?.role)),
        },
        {
            label: $t('Messages'),
            items: [
                { ...notificationTypes.message_buyer },
                { ...notificationTypes.message_seller },
                { ...notificationTypes.message_logistician },
                { ...notificationTypes.message_transporter },
            ].filter((group) => !group.roles || group.roles.includes($me.get()?.role)),
        },
    ]);

    // Fetch notifications
    const fetchNotifications = () => {
        notificationStore.query = {};

        if (activeTabIndex.value === 1) {
            notificationStore.query.read = 0;
        }

        if (activeFilter.value !== 'all') {
            notificationStore.query.payload = { group: activeFilter.value };
        }

        notificationStore.fetch({ init: true });
    };

    // Load more notifications for infinite scrolling
    const loadMoreNotifications = () => {
        if (!loadingMore.value && notificationStore.hasMore) {
            loadingMore.value = true;
            notificationStore.loadMore(() => {
                loadingMore.value = false;
            });
        }
    };

    // Marquer les notifications sélectionnées comme lues
    const markAsRead = () => {
        loadingRead.value = true;
        notificationStore.markSelectedAsRead(() => {
            loadingRead.value = false;
        });
    };

    // Group notifications by date
    const notificationsGroupedByDate = computed(() => {
        return Object.entries(
            notificationStore.data.reduce((acc, notification) => {
                let dateDiff = new Date() - new Date(notification.createdAt);
                let oneDay = 24 * 60 * 60 * 1000;

                let group = 'More than a week ago';
                if (dateDiff < oneDay) {
                    group = 'Today';
                } else if (dateDiff < 7 * oneDay) {
                    group = 'Last seven days';
                }

                if (!acc[group]) acc[group] = [];
                acc[group].push(notification);
                return acc;
            }, {})
        ).map(([group, notifications]) => ({
            group,
            notifications,
        }));
    });

    // Charge les notifications lorsqu'on scroll
    const handleScroll = (event) => {
        const element = event.target;
        const threshold = 50; // Set the threshold to 50 pixels

        // Check if there are more notifications to load
        if (!notificationStore.hasMore) {
            return; // Stop if there are no more notifications to load
        }

        // Trigger load more when within 50px of the bottom
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + threshold) {
            loadMoreNotifications();
        }
    };

    onMounted(() => {
        notificationStore.init();
    });
</script>

<template>
    <div class="flex">
        <!-- Bouton notifications -->
        <a href="#" id="notifications__button" @click="notificationIsOpen = true">
            <!-- Icon + badge si unread > 0 -->
            <span
                v-if="notificationStore.unread > 0"
                v-badge.danger="notificationStore.unread"
                class="p-badge-no-gutter p-overlay-badge"
            >
                <Icon name="material-symbols:notifications-outline" size="1.6rem" />
            </span>

            <!-- Simplement icon si unread = 0 -->
            <span v-else>
                <Icon name="material-symbols:notifications-outline" size="1.6rem" />
            </span>
        </a>

        <PrimevueSidebar
            id="notifications__sidebar"
            v-model:visible="notificationIsOpen"
            position="right"
        >
            <!-- Titre + nb de notifications non lues -->
            <template #header>
                <p class="flex gap-2 align-items-center">
                    <span class="text-xl font-medium">{{ $t('Notifications') }}</span>
                    <span class="p-badge p-component p-badge-danger text-center">{{
                        notificationStore.unread
                    }}</span>
                </p>
            </template>

            <!-- Filtre par type de notifications -->
            <div class="notification__header p-3">
                <PrimevueDropdown
                    v-model="activeFilter"
                    class="w-full"
                    :options="filters"
                    :optionLabel="(option) => option.label"
                    optionValue="value"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    @update:modelValue="fetchNotifications"
                >
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                        <PrimevueBadge
                            v-if="notificationStore.unreadCounts[slotProps.option.value] > 0"
                            :value="notificationStore.unreadCounts[slotProps.option.value]"
                            severity="danger"
                            class="ml-2"
                        />
                    </template>
                </PrimevueDropdown>
            </div>

            <div class="notification__header flex align-items-center pr-1">
                <!-- Tabs "tout" et "non lu" -->
                <PrimevueTabMenu
                    v-model:activeIndex="activeTabIndex"
                    :model="[{ label: $t('All') }, { label: $t('Unread') }]"
                    style="margin-bottom: -1px"
                    @update:activeIndex="fetchNotifications"
                />

                <!-- Bouton marquer comme lu -->
                <PrimevueButton
                    id="notifications__read__button"
                    :label="
                        notificationStore.selected.length
                            ? $t('Mark as read')
                            : $t('Mark all as read')
                    "
                    text
                    :loading="loadingRead"
                    class="ml-auto"
                    :class="{ empty: notificationStore.data.length === 0 }"
                    :disabled="notificationStore.unread === 0"
                    @click="markAsRead"
                />
            </div>

            <!-- Liste des notifications -->
            <div
                class="notification__list"
                @scroll="handleScroll"
                :style="{ height: '906px', overflowY: 'auto' }"
            >
                <div v-for="group in notificationsGroupedByDate" :key="group.group">
                    <div class="notification__group">{{ $t(group.group) }}</div>
                    <div
                        v-for="notification in group.notifications"
                        :key="notification.id"
                        class="notification"
                        :class="{ unread: !notification.read }"
                        @mouseenter="notification.hover = true"
                        @mouseleave="notification.hover = false"
                    >
                        <Redirect
                            v-if="notification.payload?.urlCTA"
                            :to="notification.payload?.urlCTA"
                        />

                        <div class="flex justify-content-center flex-shrink-0 w-3rem">
                            <NovuIcon
                                v-if="
                                    notification.actor?.data &&
                                    !notification.hover &&
                                    !notification.selected
                                "
                                :type="notification.actor?.data"
                                :notificationType="notificationTypes[notification.payload.group]"
                                :severity="notification.payload?.severity"
                            />

                            <PrimevueCheckbox
                                v-else
                                v-model="notification.selected"
                                :binary="true"
                            />
                        </div>

                        <div class="notification__content">
                            <p class="notification__text" v-html="notification.content"></p>
                            <p class="notification__date">
                                {{ asDate(notification.createdAt, true) }}
                            </p>
                        </div>

                        <div v-if="notification.payload?.urlCTA" class="ml-auto">
                            <Icon name="ci:external-link" size="1.6rem" />
                        </div>
                    </div>
                </div>
                <div v-if="loadingMore" class="loading-spinner">
                    <ProgressSpinner style="width: 3rem" />
                </div>
                <div v-else-if="!notificationStore.data.length" id="notifications__empty">
                    <Icon name="material-symbols:notifications-outline" size="3rem" />
                    <h3>{{ $t('No notification.') }}</h3>
                    <p>{{ $t('Check back soon for the latest updates.') }}</p>
                </div>
            </div>
        </PrimevueSidebar>
    </div>
</template>

<style lang="scss">
    #notifications__button {
        padding-right: 1rem;
        color: #5a5a5a;

        .p-badge {
            height: 1.2rem;
            line-height: 1.2rem;
            min-width: 1.2rem;
            border-radius: 50%;
            font-size: 0.6rem;
        }
    }

    #notifications__sidebar {
        width: 30rem;

        .p-sidebar-header {
            padding: 2rem 1rem;
            border-bottom: 1px solid var(--border-color);

            .p-sidebar-header-content {
                @include text-2xl;
                width: 100%;
            }

            .p-badge {
                border-radius: 50%;
            }
        }

        .empty .p-badge {
            background-color: #9e9e9e;
        }

        .p-button.empty {
            color: #9e9e9e;
        }

        .p-sidebar-content {
            @include flex-column;
            padding: 0;
        }

        #notifications__read__button,
        #notifications__show__more {
            text-transform: uppercase;
            font-weight: 600;
        }

        #notifications__list {
            overflow: auto;
            height: 100%;
        }

        .notification__header {
            border-bottom: 1px solid var(--border-color);
        }

        .notification__group {
            padding: 0.5rem 1rem;
            font-weight: 500;
            background: var(--surface-50);
            border-bottom: 1px solid var(--border-color);
        }

        .notification {
            position: relative;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 100%;
            padding: 1rem 0.5rem;
            transition: background-color 0.2s ease-in-out;

            &:not(:last-of-type) {
                border-bottom: 1px solid var(--border-color);
            }

            &:not(.unread):hover {
                background-color: rgba(black, 0.05);
            }

            .notification__content {
                @include flex-column;
                gap: 0.5rem;
            }

            .notification__text {
                color: var(--text-black);
                font-size: 15px;
            }

            &.unread {
                background-color: #f5f9ff;

                .notification__text {
                    font-weight: 600;
                }
            }

            .notification__date {
                @include text-sm;
                color: var(--text-gray);
            }

            .p-checkbox {
                padding: 0.5rem;
                box-sizing: content-box;
                z-index: 3;
            }
        }
    }
    .loading-spinner {
        @include flex-center;
        padding: 1rem;
    }
    #notifications__empty {
        @include flex-center;
        @include flex-column;
        gap: 1rem;
        padding: 6rem 2.25rem;
        color: var(--text-gray);

        h3 {
            @include text-2xl;
            color: var(--text-black);
            font-weight: normal;
        }

        p {
            @include text-md;
        }
    }
</style>
